import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface faq {
  id: number;
  question: string;
  answer: string;
}

interface IFaq {
  faqs: faq[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function FAQ({ faqs }: IFaq) {
  return (
    <div className="pb-24">
      <div className="mx-auto max-w-8xl px-6 pt-32">
        <div className="mx-auto max-w-8xl divide-y-2 divide-black">
          <p className="text-5xl sm:text-6xl font-bold text-center leading-tight pb-12">
            Fragen Antworten
          </p>
          <dl className="mt-6 divide-y-2 divide-black">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.id}>
                {({ open }) => (
                  <>
                    <dt className="h-full">
                      <Disclosure.Button className="flex hover:px-5 py-6 w-full h-full transition-all duration-300 items-start justify-between text-left text-black">
                        <span className="font-semibold text-xl text-black">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform text-black transition-all duration-300"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Transition
                      show={open}
                      enter="transition duration-1000 ease-out"
                      enterFrom="transform opacity-0"
                      enterTo="transform opacity-1000"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform opacity-1000"
                      leaveTo="transform opacity-0"
                    >
                      <Disclosure.Panel
                        static
                        as="dd"
                        className="mt-2 pr-12 pb-12"
                      >
                        <p
                          className="text-base text-black leading-loose"
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        />
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
