import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import ArtistPresentation from "../components/ArtistPresentation";
import FAQ from "../components/FAQ";
import { FullwidthImage } from "../components/FullwidthImage";
import Hero from "../components/Hero";
import HighlightText from "../components/HighlightText";
import MintTimerTeaser from "../components/MintTimerTeaser";
import Navbar from "../components/Navbar";
import { Preloader } from "../components/Preloader";
import AndreWagner from "../public/images/andre_wagner.jpg";
import CameraParallax from "../public/images/camera-parallax.jpg";
import PhotopiaMesse from "../public/images/camera_creative_public.jpg";
import MesseLogo from "../public/images/messe-hamburg-logo.svg";

import AndreWagnerNFT from "../public/images/photographers/andre_wagner_small.jpg";
import BenHopper from "../public/images/photographers/ben_hopper_small.jpg";
import CarolineHeineke from "../public/images/photographers/carolineheineke_small.jpg";
import ChristianPopkes from "../public/images/photographers/christian_popkes_small.jpg";
import DennisSchmelz from "../public/images/photographers/dennis_schmelz_small.jpg";
import FrankHerfort from "../public/images/photographers/frank_herfort_small.jpg";
import HeikoHellwig from "../public/images/photographers/heiko_hellwig_small.jpg";
import HelmutGrill from "../public/images/photographers/helmut_grill_small.jpg";
import JanHolleben from "../public/images/photographers/janholleben_small.jpg";
import JanSchlegel from "../public/images/photographers/janschlegel_small.jpg";
import KaterinaBelinka from "../public/images/photographers/katerina_belinka_small.jpg";
import RainerHosch from "../public/images/photographers/rainer_hosch_small.jpg";
import SandraBartocha from "../public/images/photographers/sandra_bartocha_small.jpg";
import SimonPuschmann from "../public/images/photographers/simonpuschmann_small.jpg";
import StephanGabriel from "../public/images/photographers/stephan_gabriel_small.jpg";
import ThomasPoehler from "../public/images/photographers/thomas_poehler_small.jpg";

import NFTMinten from "../public/images/videos/ein-nft-minten.jpg";
import MetamaskEinrichten from "../public/images/videos/metamask-einrichten.jpg";
import PhotopiaNFT from "../public/images/videos/photopia-nft.jpg";
import NFTMintenSmartphone from "../public/images/videos/nft-minten-smartphone.jpg";

const faqs = [
  {
    id: 1,
    question: "Was ist ein Wallet?",
    answer:
      "Das Wallet ist eine digitale Geldbörse, die als eine Art Bankkonto für ihre Transaktionen im Crypto Space fungiert. Die Wallet Adresse ist vergleichbar mit der IBAN und ermöglicht es dir Kryptowährungen und NFTs einfach, transparent und schnell zu handeln. Bei der Einrichtung wird das grundlegende Konzept des Zwei-Schlüssel-Systems implementiert: Mit dem Public Key kannst du Transaktionen empfangen, während der Private Key zum Senden von Transaktionen erforderlich ist.",
  },
  {
    id: 11,
    question: "Was ist ein NFT?",
    answer: `Lerne <a class="underline" target="_blank" href="https://youtu.be/DNT8IOkVYqo">hier</a> mehr über NFTs.`,
  },
  {
    id: 2,
    question: "Wie erstelle ich ein Wallet?",
    answer:
      'Um bei uns minten zu können, benötigst Du ein MetaMask-Wallet - das am weitesten verbreitete Wallet. In wenigen Schritten kannst du auf dieser Webseite ein eigenes Wallet erstellen: <a href="https://metamask.io" target="_blank">https://metamask.io</a>. MetaMask kannst Du mit unterschiedlichen Browsern (Chrome, Firefox oder Edge) nutzen. Beachte bitte, dass Safari aktuell die Verbindung mit Wallets nicht unterstützt. Eine Schritt-für-Schritt-Anleitung findest Du hier: <a href="https://www.nftews.de/metamask-wallet-anleitung/" target="_blank">https://www.nftews.de/metamask-wallet-anleitung/</a>',
  },
  {
    id: 3,
    question:
      "Wie funktioniert der erstmalige Kauf eines NFTs nach dem Launch?",
    answer:
      "Der erstmalige Kauf eines NFTs wird auch Mint genannt. Minting beschreibt den Prozess, mit dem neue Token für ein Blockchain-Netzwerk registriert werden. Er ist vergleichbar mit dem Prägen von Geld in traditionellen Banken. Der Mint erfolgt mit Hilfe der Wallets, welche am Stichtag mit der Minting-Page verbunden wird. Hierbei wird der NFT mit einem individuellen Code verschlüsselt, der den persönlichen Besitzanspruch verifiziert. Die Krypto-Wallet wird damit der einzige Zugangspass zu deinem persönlichen NFT Sammlerstück.",
  },
  {
    id: 4,
    question: "Wo kann ich meinen NFT nach dem Kauf einsehen?",
    answer:
      "Nach dem Kauf kannst du deinen NFT bei Plattformen wie Open Sea oder LooksRare einsehen. Hierfür musst du lediglich dein Wallet mit der Plattform verbinden und dein Profil aufrufen. Durch die Verbindung mit der Wallet verifiziert die Blockchain dein Eigentum an dem NFT und zeigt dir diesen an.",
  },
  {
    id: 9,
    question: "Wo kann ich individuelle Fragen stellen?",
    answer:
      "Du kannst uns sowohl über Instagram, Facebook als auch auf E-Mail gerne erreichen!",
  },
  {
    id: 10,
    question: "Wie viele NFTs kann ich minten?",
    answer: "Pro Wallet kann ein NFT gemintet werden",
  },
];

const arr = [
  {
    id: 13,
    name: "André Wagner – Gundicha Temple",
    description:
      "Das neueste Werk aus der Serie „Reflections of India“ erschließt durch Infrarot-Fotografie für den Menschen das surreale, spirituell-transmentale Spektrum.",
    date: "2022 / Puri, Odisha, Indien",
    camera: "Fuji X100F Infrared",
    edited: "Ja",
    artistDescription:
      "1980 in Deutschland geboren; ausgebildeter Fotograf; seit 20 Jahren künstlerische Fotografie; spirituelle Ästhetik von Zeit und Raum, Ewigkeit und Vergänglichkeit; zahlreiche internationale Preise",
    image: AndreWagnerNFT,
    socials: {
      twitter: "https://twitter.com/andrewagnereth",
      instagram: "https://www.instagram.com/andrewagnerphotographer/",
      website: "https://andrewagner.com/",
      opensea:
        "https://opensea.io/de-DE/collection/reflections-of-india-by-andre-wagner",
    },
  },
  {
    id: 16,
    name: "Ben Hopper – Burning Man",
    description:
      "Daniel Murphy fusioniert auf dem Festival Burning Man mit der Skulptur „Realize“ des Metal-Art-Künstlers Gene Tully.",
    date: "2016 / Black Rock Desert, Nevada, USA",
    camera: "Canon EOS 5D Mark II",
    edited: "Ja",
    artistDescription:
      "1982 in Israel geboren; jetzt London; weltweit tätiger Künstler, Porträtist und Konzeptfotograf; Schwerpunkt: menschliche Figur, Masken, Scham, Konflikte, menschliche Verwandlung",
    image: BenHopper,
    socials: {
      twitter: "https://twitter.com/BenHopper",
      instagram: "https://www.instagram.com/benhopper/",
      website: "https://therealbenhopper.com/",
      opensea: "https://linktr.ee/BenHopper",
    },
  },
  {
    id: 2,
    name: "Caroline Heinecke – Far Away",
    description:
      "Mit dem ihr eigenen Gespür für Kompositionen von Farben, Formen und Arrangements, zierte ihr erstes NFT als Plattencover eine 2020 erschienene LP.",
    date: "2019 / Berlin, Deutschland",
    camera: "Nikon D850",
    edited: "Nein",
    artistDescription:
      "1986 in Deutschland geboren; Studium Fotografie; Visuelle Kommunikation; leicht entrückt; zweidimensional, zentriert; lebendige Stillleben- u. Fine-Art-Fotografie für Ausstellungen und Zeitschriften",
    image: CarolineHeineke,
    socials: {
      twitter: "",
      instagram: "https://www.instagram.com/caroline.heinecke/",
      website: "https://www.carolineheinecke.com/",
      opensea: "",
    },
  },
  {
    id: 5,
    name: "Christian Popkes – Night falls on Manhattan",
    description:
      "Im Rahmen einer Kalenderproduktion entstand dieses Fotos des Empire State Building in New York City in der Dämmerung, das auch sein erstes NFT ist.",
    date: "2022, Manhattan New York, USA",
    camera: "NIKON Z7",
    edited: "Ja",
    image: ChristianPopkes,
    artistDescription:
      "1965 in Deutschland geboren; studierter Fotograf, Buchautor, Artistic Director; zahlreiche Arbeiten für diverse Zeitschriften und Firmen; Schwerpunkt: Menschen und deren Seelen; diverse Auszeichnungen",
    socials: {
      twitter: "",
      instagram: "https://www.instagram.com/christian.popkes/",
      website: "",
      opensea: "",
    },
  },
  {
    id: 12,
    name: "Dennis Schmelz – In the Woods",
    description:
      "Der deutsche Wald, aus einer Perspektive betrachtet, wie ihn sonst nur Insekten zu sehen bekommen. Ein nebliger Morgen auf dem Großen Feldberg.",
    date: "2020, Großer Feldberg im Taunus, Deutschland",
    camera: "Sony Alpha 7S III",
    edited: "Ja",
    artistDescription:
      "1988 in Deutschland geboren; Mediengestalter, professioneller Filmemacher, Coach, Dozent und Referent; offizieller Sony Ambassador; authentische, imposante Bildsprache; zahlreiche Auszeichnungen",
    image: DennisSchmelz,
    socials: {
      twitter: "https://twitter.com/DennisSchmelz",
      instagram: "https://www.instagram.com/dennisschmelz/",
      website: "https://dennisschmelz.de/",
      opensea: "https://linktr.ee/dennisschmelz",
    },
  },
  {
    id: 7,
    name: "Frank Herfort – Kiev-Vydybichi",
    description:
      "Sein erstes NFT der Metrostation Vydubychi, kurz bevor der letzte Zug einfährt zeigt die wundersam surreale, faszinierend kühle postsowjetische Welt.",
    date: "2020 / Kiew, Ukraine",
    camera: "Fuji GFX50",
    edited: "Ja",
    artistDescription:
      "1979 in Deutschland geboren; Architektur-, Dokumentar- und Industriefotograf; mehr als ein Jahrzehnt in Moskau; Bücher, unzählige Publikationen in den großen Zeitschriften; mehrere Fotopreise",
    image: FrankHerfort,
    socials: {
      twitter: "",
      instagram: "https://www.instagram.com/frank_herfort/",
      website: "https://frankherfort.com/",
      opensea: "",
    },
  },
  {
    id: 11,
    name: "Heiko Hellwig – X-Ray Car",
    description:
      "Bei allem, was uns umgibt, gibt es mehr, als das, was wir sehen. Immer spannend, die Oberflächen der realen Welt durchdringend und zur Reflexion einladend.",
    date: "2014, Bugatti Museum, Mulhouse, Frankreich",
    camera: "Nikon D810",
    edited: "Ja",
    artistDescription:
      "1960 in Deutschland geboren; jetzt Mexiko; es gibt mehr, als die Welt, die wir sehen; auf der Suche nach dem „Dahinter“; unsichtbare Ebenen werden in seinen erstaunlichen Arbeiten sichtbar und erlebbar",
    image: HeikoHellwig,
    socials: {
      twitter: "https://twitter.com/heiko_hellwig",
      instagram: "https://www.instagram.com/heiko_hellwig/",
      website: "https://www.heikohellwig.com",
      opensea: "https://sloika.xyz/heikohellwig.eth/silicon-cities",
    },
  },
  {
    id: 14,
    name: "Helmut Grill – With or without you",
    description:
      "Auch in seinem ersten NFT spielt er mit der Realität. Das Bild aus der Serie „Sceneries“ hebt durch die Spiegelung noch einmal hervor: Mit dir oder allein?",
    date: "2012 / Lake Natron, Tansania",
    camera: "Nikon DH2",
    edited: "Ja",
    artistDescription:
      "1965 in Österreich geboren; Reprofotograf, Lithograf, Fotokünstler der Fotomanipulation; entfremdet und setzt existierende Fotos in neuen Kontext; zahlreiche Ausstellungen und Publikationen",
    image: HelmutGrill,
    socials: {
      twitter: "https://twitter.com/helmutgrill",
      instagram: "https://www.instagram.com/helmutgrill/",
      website: "https://helmutgrill.com/",
      opensea: "",
    },
  },
  {
    id: 6,
    name: "Jan C. Schlegel – Raila",
    description:
      "Vor grauem Hintergrund betont dieses dokumentarische, fast surrealistische Porträt mit höchster Konzentration das Wesentliche: Das Kunstwerk Mensch",
    date: "2017 / Turkana Tribe, Kenia",
    camera: "Ebony SV45Ti",
    edited: "Nein",
    artistDescription:
      "1965 in Deutschland geboren; Vorliebe für Schwarz-Weiß-Porträts; lotet die Grenzen seines Mediums aus; Meister der Identität, Schönheit und Einzigartigkeit jedes Lebewesens in all seinen Details",
    image: JanSchlegel,
    socials: {
      twitter: "https://twitter.com/janschlegel1",
      instagram: "https://www.instagram.com/janschlegel/",
      website: "http://www.jan-schlegel.com/",
      opensea: "https://foundation.app/@janschlegel",
    },
  },
  {
    id: 3,
    name: "Jan von Holleben – Walking the Universe",
    description:
      "Ein kritisches Werk aus der Serie „Science Sucks“: Wir wollen einfach alles, greifen nach den Sternen wie im Spiel, ohne die Konsequenzen zu kennen.",
    date: "2021, Berlin, Deutschland",
    camera: "Canon 5D Mark IV",
    edited: "Nein",
    artistDescription:
      "1977 in Deutschland geboren; jetzt Paris; Kameramann, Kindertherapeut, Bildredakteur, Art Director; fotografisch 'homo ludens' - der Mensch, der spielerisch lernt; zahlreiche internationale Preise",
    image: JanHolleben,
    socials: {
      twitter: "",
      instagram: "https://www.instagram.com/jannnnnnvonholleben/",
      website: "http://www.janvonholleben.com/",
      opensea: "",
    },
  },
  {
    id: 9,
    name: "Katerina Belkina – Enter",
    description:
      "Katerinas erstes NFT zeichnet einen Albtraum: Habe mich verlaufen, kann mein Haus nicht finden, alles ist gleich, suche ewig, niemand ist da, Leere. Ich bin allein.",
    date: "2011, Moskau, Russland",
    camera: "Hasselblad H3D",
    edited: "Ja",
    artistDescription:
      "1974 in Russland geboren; jetzt D; Akademie der Künste „Petrov-Vodkin“; Ausbildung Fotografie; typisch: ihre geheimnisvollen, „gemalten“ Fotografien; zahlreiche internationale Auszeichnungen",
    image: KaterinaBelinka,
    socials: {
      twitter: "https://twitter.com/Belkina_art",
      instagram: "https://www.instagram.com/thebelki/",
      website: "https://www.belkina.art/",
      opensea: "",
    },
  },
  {
    id: 10,
    name: "Rainer Hosch – Kamasi Washington",
    description:
      "Das NFT ist Teil der Serie „Ich habe einen Traum“ und ist ein einfühlsames Porträt des US-amerikanischen Tenorsaxophonisten Kamasi Washington.",
    date: "2015, Los Angeles, USA",
    camera: "Nikon D810",
    edited: "Ja",
    artistDescription:
      "1970 in Österreich geboren; Fotografiestudium; ab 1996 USA; Kunstfotograf, Wegbereiter der NFT-Fotografie; Straßenreportagen, Lifestyle- und Beauty-Bilder; authentische Porträts von Weltstars",
    image: RainerHosch,
    socials: {
      twitter: "https://twitter.com/rainerhosch",
      instagram: "https://www.instagram.com/rainerhosch/",
      website: "https://www.rainerhosch.com/",
      opensea: "https://opensea.io/collection/52icons-rainer-hosch-genesis",
    },
  },
  {
    id: 8,
    name: "Sandra Bartocha – Freckled Light",
    description:
      "Auch in ihrem ersten NFT wird die Flüchtigkeit des Augenblicks, die Schönheit des Subtilen wie die Lichtflecken der untergehenden Sonne gefeiert.",
    date: "2020 / Binz, Deutschland",
    camera: "Nikon D850",
    edited: "Nein",
    artistDescription:
      "1980 in Deutschland geboren; Vizepräsidentin der Gesellschaft für Naturfotografie, Chefredakteurin, Autorin, Fotografin; Ausstellungen, Publikationen, zahlreiche Preise bei internationalen Wettbewerben",
    image: SandraBartocha,
    socials: {
      twitter: "https://twitter.com/sandrabartocha",
      instagram: "https://www.instagram.com/sandrabartocha/",
      website: "https://www.sandrabartocha.com/",
      opensea: "",
    },
  },
  {
    id: 1,
    name: "Simon Puschmann – Hamburg Trichromatic",
    description:
      "Trichromatische Fotografie mit Kanalverschiebung und analoger Rückteilverschiebung die eine Eisenbahnbrücke in Hamburg Zeit und Raum entrückt.",
    date: "2018 / Hamburg, Deutschland",
    camera: "Alpa Max mit Phase One IQ 3-100",
    edited: "Ja",
    artistDescription:
      "1968 in Deutschland geboren; preisgekrönter Werbefotograf und Regisseur; seit 30 Jahren kreiert er seine eigene Art von dramatischen, unerwarteten Bildern für renommierte internationale Top-Marken",
    image: SimonPuschmann,
    socials: {
      twitter: "https://twitter.com/simonpuschmann",
      instagram: "https://www.instagram.com/simonpuschmann/",
      website: "https://www.simonpuschmann.com/about/",
      opensea: "https://foundation.app/@simonpuschmann",
    },
  },
  {
    id: 15,
    name: "Stephan Gabriel – Frau in der Pariser Metro",
    description:
      "Auch in seinem ersten NFT wird der wunderbar persönliche Blickwinkel und seine jahrzehntelange Liebe zur französischen Metropole deutlich.",
    date: "2002 / Paris, Frankreich",
    camera: "Canon EOS 3 (analog auf Film Ilford Delta 3200 professional)",
    edited: "Nein",
    artistDescription:
      "1953 in Deutschland geboren; Werbekaufmann und Fotograf und Sprecher; zahlreiche Veröffentlichungen; fundierte Reisereportagen, Fotobildbände, Kalenderproduktionen; Ausstellungen; diverse Preise",
    image: StephanGabriel,
    socials: {
      twitter: "",
      instagram: "",
      website: "https://www.stephan-gabriel.de/",
      opensea: "",
    },
  },
  {
    id: 17,
    name: "Thomas Pöhler – Baden-Festival-Sammlung 01 Cassio Vasconcellos",
    description:
      "Sein erstes NFT zeigt die Ausstellung des brasilianischen Fotografen Cassio Vasconcellos als 360-Grad-Sphäre und als Tunnel projiziert.",
    date: "2021 / Baden bei Wien",
    camera: "Ricoh Theta Z1",
    edited: "Ja",
    artistDescription:
      "1966 in Deutschland geboren; Großbildjäger, Fotopodcaster, Nerdadept; Schwerpunkt seit Dunkelkammerzeiten: perfekt montierte 360°-Fotografie mit teils märchenhaft verfremdeten Ergebnissen",
    image: ThomasPoehler,
    socials: {
      twitter: "",
      instagram: "",
      website: "https://thomaspoehler.myportfolio.com/",
      opensea: "",
    },
  },
];

const Home: NextPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [windowSize, setWindowSize] = useState(0);
  const tl1 = useRef<GSAPTimeline>();
  const tl2 = useRef<GSAPTimeline>();
  const tl3 = useRef<GSAPTimeline>();
  const scrollText1 = useRef(null);
  const scrollText2 = useRef(null);
  const scrollText3 = useRef(null);

  // useEffect(() => {
  //   let mm = gsap.matchMedia();

  //   function resize() {
  //     console.log(windowSize)
  //     setWindowSize(window.innerWidth)
  //   }

  //   mm.add("(min-width: 800px)", () => {
  //     let ctx = gsap.context(() => {
  //       tl1.current = gsap.timeline({
  //         scrollTrigger: {
  //           trigger: scrollText1.current,
  //           start: "bottom bottom",
  //           end: "top top",
  //           scrub: true,
  //           invalidateOnRefresh: true,
  //           markers: false,
  //         },
  //       });

  //       tl1.current.to(scrollText1.current, {
  // x: -100,
  // autoAlpha: 1,
  // ease: "none",
  // lazy: false,
  // });

  // gsap.to(scrollText2.current, {
  //   xPercent: -50,
  //   autoAlpha: 1,
  //   ease: "none",
  //   scrollTrigger: {
  //     trigger: scrollText2.current,
  //     start: "bottom bottom",
  //     end: "top top",
  //     scrub: true,
  //     markers: false,
  //   },
  // });
  // gsap.to(scrollText3.current, {
  //   xPercent: -50,
  //   autoAlpha: 1,
  //   ease: "none",
  //   scrollTrigger: {
  //     trigger: scrollText3.current,
  //     start: "bottom bottom+=450",
  //     end: "top top",
  //     scrub: true,
  //     markers: false,
  //   },
  // });
  //     }, [scrollText1, scrollText2, scrollText3]);

  //     return () => {
  //       tl1.current?.scrollTrigger?.refresh();
  //       ScrollTrigger.addEventListener("refreshInit", resize)
  //       resize();
  //       ctx.revert();
  //       ctx.kill();
  //     };
  //   });
  // }, []);

  // const onPlayerReady: YouTubeProps["onReady"] = (event) => {
  //   // access to player in all event handlers via event.target
  //   event.target.pauseVideo();
  // };

  // const opts: YouTubeProps["opts"] = {
  //   height: "400",
  //   width: "100%",
  //   playerVars: {
  //     title: "Youtube Video",
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 0,
  //   },
  // };

  return (
    <>
      <Head>
        <title>
          PHOTOPIA NFT - Summit und Festival für Imaging #shareyourvision
        </title>
      </Head>
      <div>{isLoading && <Preloader loader={() => setLoading(false)} />}</div>
      <Navbar />
      <Hero />
      <div className="block h-full lg:hidden">
        <MintTimerTeaser />
      </div>

      <div className="mx-auto w-full px-6 pt-32 pb-12 relative overflow-hidden">
        <p
          ref={scrollText1}
          className="get-scroll-text text-9xl absolute opacity-0 text-right whitespace-nowrap translate-x-[80%] top-1/2 -z-10 font-bold text-mh-yellow h-full w-full"
        >
          step into the future
        </p>
        <p className="text-5xl sm:text-7xl trigger-text flex justify-center lg:-ml-52 font-bold relative leading-tight w-full">
          Unser erster
          <br /> NFT Drop
        </p>
      </div>
      <div className="max-w-8xl mx-auto px-6 pb-12 pt-8 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        <div className="relative h-full hidden lg:block">
          <Image
            src={PhotopiaMesse}
            alt="Camera Image"
            lazyBoundary="400px"
            placeholder="blur"
            objectFit="cover"
            layout="fill"
          />
        </div>
        <div className="relative h-full block lg:hidden">
          <Image
            src={PhotopiaMesse}
            alt="Camera Image"
            placeholder="blur"
            lazyBoundary="400px"
            objectFit="cover"
            layout="responsive"
          />
        </div>
        <p className="text-md trigger-end leading-loose">
          Vielleicht hast Du den Begriff NFT schon einmal gehört. Er steht für
          Non-Fungible Token, sinngemäß eine nicht-austauschbare Wertmarke. NFTs
          machen aus digitalen Bildern Sammelobjekte, die in Online-Galerien
          ausgestellt und gehandelt werden.{" "}
          <HighlightText>
            Die PHOTOPIA Hamburg verschenkt NFT-Fotografien als
            Erinnerungsstücke an ihre BesucherInnen und hilft beim Einstieg in
            die neue Technologie.
          </HighlightText>
          <br />
          <br />
          Die NFT- und die damit zusammenhängende Blockchain-Technologie haben
          in den letzten Jahren den Markt für digitale Kunst revolutioniert.
          Während analoge Bilder oft Unikate sind, können digitale Fotos
          normalerweise beliebig oft eins zu eins kopiert werden, ohne dass das
          Original erkennbar wäre. Für SammlerInnen sind sie daher nicht
          interessant.{" "}
          <HighlightText>
            Bei NFT-Kunstwerken, die sich in Online-Galerien befinden und
            beispielsweise über Plattformen wie OpenSea gehandelt werden, lassen
            sich EigentümerInnen und Original dagegen eindeutig identifizieren.
          </HighlightText>
        </p>
      </div>
      <div className="mx-auto px-6 w-full pt-32 relative overflow-hidden">
        <p
          ref={scrollText2}
          className="get-scroll-text-2 overflow-hidden text-9xl absolute opacity-0 text-right whitespace-nowrap translate-x-[30%] top-20 -z-10 font-bold text-mh-yellow h-full w-full"
        >
          Unsere NFTs
        </p>
        <p className="trigger-text-2 text-5xl sm:text-6xl font-bold relative text-center leading-tight">
          Unsere NFTs
        </p>
        <p className="text-md max-w-4xl mx-auto leading-loose text-center pb-12 px-6 pt-5">
          Nur im Zeitraum der PHOTOPIA vom 13. bis zum 16. Oktober kann pro
          Wallet ein NFT gemintet werden. In diesen Zeitraum bleibt es aber noch
          ein Geheimnis, von welchen FotografInnen Du ein NFT im Wallet liegen
          haben wirst. Erst nach Ablauf der Veranstaltung wird dieses Geheimnis
          gelüftet. Das genaue Datum für die Enthüllung geben wir noch auf
          Instagram und der Website bekannt.
        </p>
      </div>

      <div className="trigger-end-2 max-w-8xl mx-auto px-6">
        {arr.map((e) => (
          <div key={e.id}>
            <ArtistPresentation
              name={e.name}
              description={e.description}
              artistDescription={e.artistDescription}
              date={e.date}
              camera={e.camera}
              edited={e.edited}
              image={e.image}
              socials={e.socials}
            />
          </div>
        ))}
      </div>

      <FullwidthImage image={CameraParallax} />

      <div className="max-w-8xl overflow-hidden relative mx-auto px-6 pb-12 pt-8 grid pb-32 grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        <div className="h-full relative hidden lg:block">
          <Image
            src={AndreWagner}
            alt="Camera Image"
            objectFit="cover"
            lazyBoundary="400px"
            placeholder="blur"
            layout="fill"
          />
        </div>
        <div className="h-full relative block lg:hidden">
          <Image
            src={AndreWagner}
            alt="Camera Image"
            lazyBoundary="400px"
            objectFit="cover"
            placeholder="blur"
            layout="responsive"
          />
        </div>
        <p
          ref={scrollText3}
          className="get-scroll-text-3 opacity-0 text-8xl sm:text-9xl absolute top-10 translate-x-[80%] sm:top-0 -z-10 font-bold text-mh-yellow h-full w-full whitespace-nowrap"
        >
          André Wagner
        </p>
        <div className="w-full relative trigger-text-3 ">
          <p className="text-5xl sm:text-6xl font-bold leading-tight pb-6 relative overflow-hidden w-full">
            Konzept von <br /> André Wagner
          </p>
          <p className="text-md leading-loose">
            Das Konzept für das NFT-Projekt auf der PHOTOPIA hat der Berliner
            Fotokünstler André Wagner entwickelt{" "}
            <a
              aria-label="Andre Wagner"
              className="cursor-pointer underline"
              target="_blank"
              href="https://andrewagner.com/nft"
              rel="noreferrer"
            >
              (andrewagner.com/nft)
            </a>
            . Die Umsetzung der notwendigen Komponenten auf der Ethereum
            Blockchain wird von{" "}
            <a
              aria-label="PROCON IT"
              className="cursor-pointer underline"
              target="_blank"
              href="https://www.procon-it.de/"
              rel="noreferrer"
            >
              PROCON IT
            </a>{" "}
            aus Garching übernommen. Für die ersten Schritte im Web3, der
            Einrichtung einer eignen Crypto-Wallet sowie Hilfe beim Erhalt der
            NFTs steht das Team von ALL IN NFT den Besuchenden der PHOTOPIA
            unterstützend zur Seite. André Wagner:{" "}
            <HighlightText>
              „Ich kann mich noch genau erinnern, wie ich mich vor noch gar
              nicht allzu langer Zeit gegen ‚all diesen neumodischen Kram‘
              gesperrt habe“. „Aber irgendwann haben sich für mich, nicht nur
              als Künstler, sondern auch als Sammler, die unglaublichen
              Möglichkeiten dieser neuen Technologie erschlossen.“
            </HighlightText>
            <br />
            <br />
            Vielleicht geht es Dir ja ähnlich. Wenn Dein Interesse geweckt
            wurde, kannst Du auf der PHOTOPIA die ersten Schritte wagen – und
            später natürlich auch Deine eigenen Fotos als NFT zum Kauf anbieten!
          </p>
        </div>
      </div>
      <div className="bg-mh-yellow-light pt-32">
        <div className="max-w-8xl mx-auto px-6 pb-12 grid grid-cols-1 lg:grid-cols-2 gap-12">
          <p className="text-5xl sm:text-6xl font-bold leading-tight pb-6">
            PHOTOPIA erleichtert den Einstieg
          </p>
          <p className="text-md leading-loose">
            Soweit so gut – doch wie gelingt der Einstieg in den Handel mit
            NFTs? Neulinge sehen sich zunächst mit verwirrenden Begriffen wie
            minten, Gas-Gebühr oder Web3 und Metaverse konfrontiert.{" "}
            <HighlightText>
              Die PHOTOPIA erleichtert Interessierten den Einstieg in die neue
              Welt.
            </HighlightText>{" "}
            Verschiedene Fotografierende aus Deutschland, Österreich und der
            Schweiz stellen Ihre Werke für das NFT-Projekt zur Verfügung. Die
            Werke werden auf dem PHOTOPIA Summit auf großen Monitoren
            präsentiert. Die FotokünstlerInnen sind selber zum Teil in den
            Messehallen vor Ort und stehen für Gespräche zur Verfügung. Weitere
            ExpertInnen helfen dabei, ein Wallet für das kostenlose NFT-Foto
            anzulegen.{" "}
            <HighlightText>
              {" "}
              Der Clou: Nicht nur die NFT Fotografie selbst ist kostenlos,
              sondern die PHOTOPIA übernimmt auch die Transaktionsgebühren vor
              Ort, die bei der Erstellung der Token anfällt.
            </HighlightText>{" "}
            Besuchende der Mint-Website können sich aber auch ohne die PHOTOPIA
            zu besuchen ein NFT kostenlos sichern, müssen dann aber die
            Transaktionsgebühr bezahlen. Welches der zur Auswahl gestellten
            digitalen Sammlerstücke der oder die einzelne Besuchende erhält,
            bleibt übrigens dem Zufall überlassen.
          </p>
        </div>
        <div className="max-w-8xl mx-auto px-6 pb-0 md:pb-12 sm:py-24 py-16">
          <p className="text-5xl sm:text-6xl font-bold text-center px-6 leading-tight pb-0 md:pb-6">
            YouTube Tutorials
          </p>
          <div className="pt-12 grid grid-cols-1 md:grid-cols-2 max-w-8xl mx-auto gap-16">
            <a
              className="hover:scale-95 transition duration-300"
              href="https://youtu.be/r5upRC_Akn0"
              target="_blank"
              rel="noreferrer"
            >
              <div className="relative w-full h-full">
                <Image
                  src={NFTMinten}
                  alt="Camera Image"
                  lazyBoundary="400px"
                  placeholder="blur"
                  objectFit="cover"
                  layout="responsive"
                />
              </div>
            </a>
            <a
              className="hover:scale-95 transition duration-300"
              href="https://youtu.be/g2xW-8YC6Y0"
              target="_blank"
              rel="noreferrer"
            >
              <div className="relative w-full h-full">
                <Image
                  src={MetamaskEinrichten}
                  alt="Camera Image"
                  lazyBoundary="400px"
                  placeholder="blur"
                  objectFit="cover"
                  layout="responsive"
                />
              </div>
            </a>
            <a
              className="hover:scale-95 transition duration-300"
              href="https://youtu.be/COdOwXtHlJg"
              target="_blank"
              rel="noreferrer"
            >
              <div className="relative w-full h-full">
                <Image
                  src={NFTMintenSmartphone}
                  alt="Camera Image"
                  lazyBoundary="400px"
                  placeholder="blur"
                  objectFit="cover"
                  layout="responsive"
                />
              </div>
            </a>
            <a
              className="hover:scale-95 transition duration-300"
              href="https://youtu.be/N8Afh506Wx0"
              target="_blank"
              rel="noreferrer"
            >
              <div className="relative w-full h-full">
                <Image
                  src={PhotopiaNFT}
                  alt="Camera Image"
                  lazyBoundary="400px"
                  placeholder="blur"
                  objectFit="cover"
                  layout="responsive"
                />
              </div>
            </a>
          </div>
        </div>
        <FAQ faqs={faqs} />
      </div>
      <footer className="trigger-end-3 bg-mh-purple px-6 pt-32">
        <div className="max-w-8xl mx-auto block lg:flex justify-end">
          <a
            aria-label="YouTube"
            href="https://www.youtube.com/results?search_query=photopia"
            className="pl-12"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-4xl font-semibold hover:underline">YouTube</p>
          </a>
          <a
            aria-label="LinkedIn"
            href="https://www.linkedin.com/showcase/photopia-hamburg"
            className="pl-12"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-4xl font-semibold hover:underline">LinkedIn</p>
          </a>
          <a
            aria-label="Facebook"
            href="https://www.facebook.com/photopia.ham"
            className="pl-12"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-4xl font-semibold hover:underline">Facebook</p>
          </a>
          <a
            aria-label="Instagram"
            href="https://www.instagram.com/photopia_ham/"
            className="pl-12"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-4xl font-semibold hover:underline">Instagram</p>
          </a>
        </div>
        <div className="pt-40 pb-4 flex items-center justify-between relative">
          <div className="h-full w-32 md:w-44 relative">
            <Image src={MesseLogo} alt="Logo Messe" layout="responsive" />
          </div>
          <div className="lg:flex block">
            <a
              aria-label="Hamburg Messe Impressum"
              href="https://www.hamburg-messe.de/impressum"
              rel="noreferrer"
              target="_blank"
            >
              <p className="cursor-pointer font-semibold pl-6 hover:underline">
                Impressum
              </p>
            </a>
            <a
              aria-label="Hamburg Messe Datenschutz"
              href="https://www.hamburg-messe.de/datenschutz"
              rel="noreferrer"
              target="_blank"
            >
              <p className="cursor-pointer font-semibold pl-6 hover:underline">
                Datenschutz
              </p>
            </a>
            <a
              aria-label="Hamburg Bedingungen NFT"
              href="https://www.photopia-hamburg.com/bedingungen-nft-gallery"
              rel="noreferrer"
              target="_blank"
            >
              <p className="cursor-pointer font-semibold pl-6 hover:underline">
                NFT Bedingungen
              </p>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Home;
