export enum PHASES {
  PREMINT = 'premint',
  MINT = 'mint',
  REVEAL = 'reveal',
  POSTMINT = 'postmint'
}

// export const ROUTE_MINTING = "/minting"
export const ROUTE_MINTING = "/minting"

const ACTIVE_PHASE = PHASES.POSTMINT; // Set the current phase to display: ;

export function checkPhases(phase: PHASES) {
  return phase === ACTIVE_PHASE;
}
