import { useEffect, useRef } from "react";
import gsap from "gsap";

export const Preloader = ({ loader }: any) => {
  const tl = useRef<GSAPTimeline>();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline({
          onComplete: () => loader(),
        })
        .to(
          ref1.current,
          {
            autoAlpha: 1,
          },
          "flag"
        )
        .fromTo(
          ref2.current,
          {
            y: -200,
            x: -200,
          },
          {
            y: 0,
            x: 0,
            duration: 0.7,
          },
          "flag"
        )
        .fromTo(
          ref3.current,
          {
            y: 200,
            x: 200,
          },
          {
            y: 0,
            x: 0,
            duration: 0.7,
          },
          "flag+=0.35"
        )
        .fromTo(
          ref4.current,
          {
            scale: 0,
            transformOrigin: "center center",
          },
          {
            scale: 1,
            transformOrigin: "center center",
            duration: 0.25,
          },
          "flag+=0.95"
        )
        .to(
          ref2.current,
          {
            y: -200,
            x: -200,
            duration: 0.7,
          },
          "fade-out+=0.4"
        )
        .to(
          ref3.current,
          {
            y: 200,
            x: 200,
            duration: 0.7,
          },
          "fade-out+=0.4"
        )
        .to(
          ref4.current,
          {
            scale: 0,
            transformOrigin: "center center",
            duration: 0.2,
          },
          "fade-out+=0.4"
        )
        .to(
          ref5.current,
          {
            duration: 0.65,
            autoAlpha: 0,
          },
          "fade-out+=0.55"
        );
    }, [ref1, ref2, ref3, ref4, ref5]);

    return () => {
      ctx.revert();
    };
  }, []);

  useEffect(() => {
    const tl = gsap.timeline({
      onComplete: () => loader(),
    });

    tl.to(
      ref1.current,
      {
        autoAlpha: 1,
      },
      "flag"
    );

    tl.fromTo(
      ref2.current,
      {
        y: -200,
        x: -200,
      },
      {
        y: 0,
        x: 0,
        duration: 0.7,
      },
      "flag"
    );

    tl.fromTo(
      ref3.current,
      {
        y: 200,
        x: 200,
      },
      {
        y: 0,
        x: 0,
        duration: 0.7,
      },
      "flag+=0.35"
    );

    tl.fromTo(
      ref4.current,
      {
        scale: 0,
        transformOrigin: "center center",
      },
      {
        scale: 1,
        transformOrigin: "center center",
        duration: 0.25,
      },
      "flag+=0.95"
    );

    tl.to(
      ref2.current,
      {
        y: -200,
        x: -200,
        duration: 0.7,
      },
      "fade-out+=0.4"
    );

    tl.to(
      ref3.current,
      {
        y: 200,
        x: 200,
        duration: 0.7,
      },
      "fade-out+=0.4"
    );

    tl.to(
      ref4.current,
      {
        scale: 0,
        transformOrigin: "center center",
        duration: 0.2,
      },
      "fade-out+=0.4"
    );

    tl.to(
      ref5.current,
      {
        duration: 0.65,
        autoAlpha: 0,
      },
      "fade-out+=0.55"
    );
  });
  return (
    <div className="overflow-hidden">
      <div
        ref={ref5}
        className="bgPresenter inset-0 w-screen h-screen bg-mh-purple z-50 absolute flex justify-center items-center"
      >
        <div ref={ref1} className="w-32 h-32 logoSvg opacity-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.8 202.2">
            <g ref={ref4} className="centerPoint">
              <path
                className="fill-mh-dark"
                d="M71.2,101.5h48.3v-31.2h-48.3v31.2Z"
              />
            </g>
            <g ref={ref3} className="bottomArrow">
              <polygon
                className="fill-mh-dark"
                points="190.8 202.2 190.8 32 159.5 32 159.5 139.8 71.2 139.8 71.2 171 31.2 171 31.2 202.2 190.8 202.2"
              />
            </g>
            <g ref={ref2} className="topArrow">
              <polygon
                className="fill-mh-dark"
                points="31.2 31.2 159.5 31.2 159.5 0 0 0 0 170.3 31.2 170.3 31.2 31.2 31.2 31.2"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};
