import Image from "next/image";
import { FC } from "react";
import { FaTwitter, FaInstagram, FaGlobe } from "react-icons/fa";
import OpenSea from "../public/images/opensea-logo.svg";

interface ArtistProps {
  name: string;
  description: string;
  date: string;
  camera: string;
  edited: string;
  image: any;
  socials: any;
  artistDescription: string;
}

const ArtistPresentation: FC<ArtistProps> = ({
  name,
  description,
  date,
  camera,
  edited,
  image,
  socials,
  artistDescription
}) => {
  return (
    <div>
      <hr />
      <div className="max-w-8xl mx-auto px-6 py-16 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        <div className="h-96 relative hidden lg:block">
          <Image
            src={image}
            lazyBoundary="600px"
            alt="Camera Image"
            layout={"fill"}
            placeholder="blur"
            objectFit="contain"
            quality={50}
          />
        </div>
        <div className="h-full relative lg:hidden block">
          <Image
            src={image}
            alt="Camera Image"
            lazyBoundary="600px"
            layout={"responsive"}
            placeholder="blur"
            objectFit="contain"
            quality={25}
          />
        </div>
        <div>
          <p className="text-3xl font-semibold pb-5">{name}</p>
          <p className="text-md">{description}</p>
          <p className="text-md font-semibold pt-4">
            Steckbrief:{" "}
            <span className="font-normal">{artistDescription}</span>
          </p>
          <p className="text-md font-semibold pt-4">
            Aufnahmedatum: <span className="font-normal">{date}</span>
          </p>
          <p className="text-md font-semibold pt-4">
            Kamera: <span className="font-normal">{camera}</span>
          </p>
          <p className="text-md font-semibold pt-4">
            Digitale Nachbearbeitung:{" "}
            <span className="font-normal">{edited}</span>
          </p>
          <div className="pt-4 flex gap-4">
            {socials?.twitter && (
              <a
                aria-label="twitter"
                href={socials.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-12 w-12 cursor-pointer content-center items-center justify-center rounded-full border border-mw-text text-mw-text duration-75 hover:scale-110">
                  <FaTwitter className="h-6 h-full w-6" />
                </div>
              </a>
            )}
            {socials?.instagram && (
              <a
                aria-label="instagram"
                href={socials.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-12 w-12 cursor-pointer content-center items-center justify-center rounded-full border border-mw-text text-mw-text duration-75 hover:scale-110">
                  <FaInstagram className="h-6 h-full w-6" />
                </div>
              </a>
            )}
            {socials?.website && (
              <a
                aria-label="website"
                href={socials.website}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-12 w-12 cursor-pointer content-center items-center justify-center rounded-full border border-mw-text text-mw-text duration-75 hover:scale-110">
                  <FaGlobe className="h-6 h-full w-6" />
                </div>
              </a>
            )}
            {socials?.opensea && (
              <a
                aria-label="opensea"
                href={socials.opensea}
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-12 w-12 relative cursor-pointer flex content-center items-center relative justify-center rounded-full border border-mw-text text-mw-text duration-75 hover:scale-110">
                  <div className="h-7 w-7">
                    <Image layout="responsive" src={OpenSea} alt="opensea logo" />
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistPresentation;
