import Image from "next/image";
import { useEffect, useRef } from "react";
import gsap from "gsap";

export const FullwidthImage = ({ image }: any) => {
  const parallax = useRef(null);
  const tl = useRef<GSAPTimeline>();

  /*  useEffect(() => {
    let ctx = gsap.context(() => {
      tl.current = gsap.timeline()
        .fromTo(parallax.current, {
          yPercent: -20,
          ease: "none",
        }, {
          yPercent: 20,
          ease: "none",
          scrollTrigger: {
            trigger: parallax.current,
            start: "top bottom",
            scrub: true,
            markers: false,
          },
        });
    }, parallax);

    return () => {
      ctx.revert();
    };
  }, []);*/

  return (
    <div className="relative h-[70vh] my-32 overflow-hidden">
      {/* <div
          ref={parallax}
        className="h-[120%] -translate-y-[20%] relative parallax w-full"
      > */}
      <div ref={parallax} className="h-[100%] relative parallax w-full">
        <Image
          src={image}
          alt="Camera Image"
          lazyBoundary="600px"
          placeholder="blur"
          objectFit="cover"
          className="h-full w-full"
          layout="fill"
        />
      </div>
    </div>
  );
};
