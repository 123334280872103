import { FC, useEffect, useState } from "react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { intervalToDuration } from "date-fns";
import { checkPhases, PHASES, ROUTE_MINTING } from "../helpers/phases";
import { CONTRACTS } from "../config/contract";

interface Props {
  flex?: boolean;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const MintTimerTeaser: FC<Props> = ({ flex }) => {
  const [duration, setDuration] = useState<any>({
    start: new Date(),
    end: new Date(),
  });

  function appendZero(time: any) {
    return time < 10 ? `0${time}` : time;
  }

  useEffect(() => {
    if (checkPhases(PHASES.MINT)) {
      setDuration(
        intervalToDuration({
          start: new Date(),
          end: new Date(2022, 10, 16, 18, 0, 0),
        })
      );
    } else {
      setDuration(
        intervalToDuration({
          start: new Date(),
          end: new Date(2022, 10, 19, 19, 0, 0),
        })
      );
    }
  }, []);

  const displayMintTeaser = () => {
    if (checkPhases(PHASES.PREMINT)) {
      return (
        <p className="text-4xl sm:text-5xl font-semibold px-6 pt-3 pb-4 sm:leading-snug leading-tight">
          Minte bald <br />
          den ersten NFT
        </p>
      );
    }

    if (checkPhases(PHASES.REVEAL)) {
      return (
        <p className="text-4xl sm:text-5xl font-semibold px-6 pt-3 pb-4 sm:leading-snug leading-tight">
          Danke! <br />
          Der Mint ist vorbei.
        </p>
      );
    }

    if (checkPhases(PHASES.POSTMINT)) {
      return (
        <p className="text-4xl sm:text-5xl font-semibold sm:mr-12 sm:mb-9 px-6 pt-3 pb-4 sm:leading-snug leading-tight">
          Mint vorbei! <br />
          Viel Spaß mit <br />
          deinem NFT.
        </p>
      );
    }

    return (
      <p className="text-4xl sm:text-5xl font-semibold px-6 pt-3 pb-4 sm:leading-snug leading-tight">
        Jetzt den ersten
        <br /> NFT minten
      </p>
    );
  };

  const displayCountdown = () => {
    if (checkPhases(PHASES.PREMINT)) {
      return (
        <div className=" block sm:flex justify-between items-center pb-6 pt-5 px-6">
          <p className="text-sm sm:text-md sm:pb-0 pb-2">
            Verkauf <br className="sm:block hidden" />
            eröffnet
          </p>
          <div className="text-4xl sm:text-5xl flex sm:justify-end font-semibold text-right">
            <div>
              <p className="w-16 text-center">{appendZero(duration.days)}</p>
              <p className="text-sm font-normal text-center">Tage</p>
            </div>
            <p className="text-center w-12">:</p>
            <div>
              <p className="w-16 text-center">{appendZero(duration.hours)}</p>
              <p className="text-sm font-normal text-center">Stunden</p>
            </div>
            <p className="text-center w-12">:</p>
            <div>
              <p className="w-16 text-center">{appendZero(duration.minutes)}</p>
              <p className="text-sm font-normal text-center">Minuten</p>
            </div>
          </div>
        </div>
      );
    }

    if (checkPhases(PHASES.REVEAL)) {
      return (
        <div className=" block sm:flex justify-between items-center pb-6 pt-5 px-6">
          <p className="text-sm sm:text-md sm:pb-0 pb-2">
            Nur noch wenige <br className="sm:block hidden" />
            Augenblicke <br className="sm:block hidden" /> zur Enthüllung
          </p>
          <div className="text-4xl sm:text-5xl flex sm:justify-end font-semibold text-right">
            <div>
              <p className="w-16 text-center">{appendZero(duration.days)}</p>
              <p className="text-sm font-normal text-center">Tage</p>
            </div>
            <p className="text-center w-12">:</p>
            <div>
              <p className="w-16 text-center">{appendZero(duration.hours)}</p>
              <p className="text-sm font-normal text-center">Stunden</p>
            </div>
            <p className="text-center w-12">:</p>
            <div>
              <p className="w-16 text-center">{appendZero(duration.minutes)}</p>
              <p className="text-sm font-normal text-center">Minuten</p>
            </div>
          </div>
        </div>
      );
    }

    if (checkPhases(PHASES.POSTMINT)) {
      return;
    }

    return (
      <div className=" block sm:flex justify-between items-center pb-6 pt-5 px-6">
        <p className="text-sm sm:text-md sm:pb-0 pb-2">
          Verkauf <br className="sm:block hidden" />
          schließt
        </p>
        <div className="text-4xl sm:text-5xl flex sm:justify-end font-semibold text-right">
          <div>
            <p className="w-16 text-center">{appendZero(duration.days)}</p>
            <p className="text-sm font-normal text-center">Tage</p>
          </div>
          <p className="text-center w-12">:</p>
          <div>
            <p className="w-16 text-center">{appendZero(duration.hours)}</p>
            <p className="text-sm font-normal text-center">Stunden</p>
          </div>
          <p className="text-center w-12">:</p>
          <div>
            <p className="w-16 text-center">{appendZero(duration.minutes)}</p>
            <p className="text-sm font-normal text-center">Minuten</p>
          </div>
        </div>
      </div>
    );
  };

  const displayLink = () => {
    if (checkPhases(PHASES.PREMINT)) {
      return (
        <button
          disabled
          className="bg-mh-yellow disabled:bg-neutral-300 hover:bg-mh-yellow-light w-full flex justify-between items-center text-left px-6 py-5 font-semibold"
        >
          Jetzt NFT Minten
          <ArrowLongRightIcon width={24} height={24} />
        </button>
      );
    }

    if (checkPhases(PHASES.POSTMINT) || checkPhases(PHASES.REVEAL)) {
      return (
        <a
          aria-label="opensea"
          href={`https://opensea.io/collection/photopia2022`}
          target="_blank"
          className="bg-mh-yellow disabled:bg-neutral-300 hover:bg-mh-yellow-light w-full flex justify-between items-center text-left px-6 py-5 font-semibold"
          rel="noreferrer"
        >
          Bei OpenSea kaufen
          <ArrowLongRightIcon width={24} height={24} />
        </a>
      );
    }

    return (
      <Link href={ROUTE_MINTING}>
        <button className="bg-mh-yellow hover:bg-mh-yellow-light w-full flex justify-between items-center text-left px-6 py-5 font-semibold">
          Jetzt NFT Minten
          <ArrowLongRightIcon width={24} height={24} />
        </button>
      </Link>
    );
  };

  return (
    <div
      className={classNames(
        flex ? "flex" : "block",
        "justify-end h-full items-end content-end items-end"
      )}
    >
      <div className="bg-white">
        <p className="text-md font-semibold px-6 pt-4 pb-2">PHOTOPIA Hamburg</p>
        {displayMintTeaser()}
        {displayCountdown()}
        {displayLink()}
      </div>
    </div>
  );
};

export default MintTimerTeaser;
