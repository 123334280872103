import type { NextPage } from "next";
import Image from "next/image";
import { useEffect, useRef } from "react";
import CameraHero from "../public/images/creative-bg-camera.jpg";
import MintTimerTeaser from "./MintTimerTeaser";
import { gsap } from "gsap";

const Hero: NextPage = () => {
  const heroRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(heroRef.current, {
        scale: 1.04,
        duration: 4.25,
        repeat: -1,
        yoyo: true,
      });
    }, heroRef);

    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <div className="w-full lg:h-screen h-[85vh] relative">
      <div className="h-full">
        <div className="mx-auto px-6 flex items-center h-full lg:h-auto lg:block">
          <p className="lg:text-9xl sm:text-8xl text-7xl text-white lg:translate-y-1/2 lg:absolute font-bold">
            step <br />
            into the <br /> future
          </p>
        </div>

        <div className="hidden h-full lg:block">
          <MintTimerTeaser flex={true} />
        </div>

        <div className="overflow-hidden absolute top-0 h-full w-full -z-10">
          <div
            ref={heroRef}
            className="pointer-events-none relative w-full h-full overflow-hidden"
          >
            <Image
              src={CameraHero}
              priority
              layout={"fill"}
              placeholder="blur"
              className="camera-background -z-10 pointer-events-none"
              objectFit="cover"
              alt="camera hero image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
