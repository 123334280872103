import type { NextPage } from "next";
import Image from "next/image";
import Link from "next/link";
import Logo from "../public/images/photopia-logo.svg";
import { CONTRACTS } from "../config/contract";
import { checkPhases, PHASES, ROUTE_MINTING } from "../helpers/phases";

const Navbar: NextPage = () => {
  const displayButton = () => {
    if (checkPhases(PHASES.PREMINT)) {
      return (
        <button
          disabled
          className="text-black disabled:bg-neutral-300 px-5 py-2 sm:px-12 sm:py-3 font-semibold transition-colors duration-400 cursor-pointer bg-mh-yellow hover:bg-mh-yellow-light"
        >
          Mint NFT
        </button>
      );
    }

    if (checkPhases(PHASES.POSTMINT) || checkPhases(PHASES.REVEAL)) {
      return (
        <a
          aria-label="OpenSea"
          href={`https://opensea.io/collection/photopia2022`}
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-black px-5 py-2 sm:px-12 sm:py-3 font-semibold transition-colors duration-400 cursor-pointer bg-mh-yellow hover:bg-mh-yellow-light">
            OpenSea
          </p>
        </a>
      );
    }

    return (
      <Link href={ROUTE_MINTING}>
        <p className="text-black px-5 py-2 sm:px-12 sm:py-3 font-semibold transition-colors duration-400 cursor-pointer bg-mh-yellow hover:bg-mh-yellow-light">
          Mint NFT
        </p>
      </Link>
    );
  };

  return (
    <div className="absolute px-6 py-4 w-full z-10">
      <div className="flex justify-between h-20 items-center">
        <div className="w-32 sm:w-44">
          <Image layout="responsive" src={Logo} alt="Logo Messe" />
        </div>
        {displayButton()}
      </div>
    </div>
  );
};

export default Navbar;
